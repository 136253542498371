var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.show}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-card',{staticClass:"card-body"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Poste de Referencia ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Poste de Referencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.fallaBt.posteReferencia),callback:function ($$v) {_vm.$set(_vm.fallaBt, "posteReferencia", $$v)},expression:"fallaBt.posteReferencia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Selecciona el Tipo de Falla ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Tipo de Falla","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.tiposFalla},model:{value:(_vm.fallaBt.tipoFalla),callback:function ($$v) {_vm.$set(_vm.fallaBt, "tipoFalla", $$v)},expression:"fallaBt.tipoFalla"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[(_vm.fallaBt.tipoFalla && _vm.fallaBt.tipoFalla.id === 2)?_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Número de Transformador ")]),_c('b-form-group',[_c('b-form-input',{model:{value:(_vm.fallaBt.noTransformador),callback:function ($$v) {_vm.$set(_vm.fallaBt, "noTransformador", $$v)},expression:"fallaBt.noTransformador"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Dirección ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-no-resize","state":errors.length > 0 ? false:null,"rows":"2","no-resize":""},model:{value:(_vm.direccion),callback:function ($$v) {_vm.direccion=$$v},expression:"direccion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Fotografía Panorámica ")]),_c('b-form-group',[_c('imagen',{key:'panoramica',attrs:{"leyenda":'panoramica'},on:{"cargar-file":_vm.imagenPanoramica}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Solución Provisional ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Solución Provisional","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-no-resize","state":errors.length > 0 ? false:null,"rows":"2","no-resize":""},model:{value:(_vm.fallaBt.solucionProvisional),callback:function ($$v) {_vm.$set(_vm.fallaBt, "solucionProvisional", $$v)},expression:"fallaBt.solucionProvisional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Fotografía de Solución ")]),_c('b-form-group',[_c('imagen',{key:'fotografiaSolucion',attrs:{"leyenda":'fotografiaSolucion'},on:{"cargar-file":_vm.imagenSolucion}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Dictamen ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Dictamen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-no-resize","state":errors.length > 0 ? false:null,"rows":"2","no-resize":""},model:{value:(_vm.fallaBt.dictamen),callback:function ($$v) {_vm.$set(_vm.fallaBt, "dictamen", $$v)},expression:"fallaBt.dictamen"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Recomendación ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Recomendacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-no-resize","state":errors.length > 0 ? false:null,"rows":"2","no-resize":""},model:{value:(_vm.fallaBt.recomendacion),callback:function ($$v) {_vm.$set(_vm.fallaBt, "recomendacion", $$v)},expression:"fallaBt.recomendacion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Observaciones ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Recomendación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-no-resize","state":errors.length > 0 ? false:null,"rows":"2","no-resize":""},model:{value:(_vm.fallaBt.observaciones),callback:function ($$v) {_vm.$set(_vm.fallaBt, "observaciones", $$v)},expression:"fallaBt.observaciones"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(!_vm.isNew || (_vm.isNew && !_vm.isReadOnly))?_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Guardar ")])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }