<template>
  <div>
    <b-overlay
      :show="show"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-card
              class="card-body"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <h5 class="text-primary">
                    Poste de Referencia
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Poste de Referencia"
                      rules="required"
                    >
                      <b-form-input
                        v-model="fallaBt.posteReferencia"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <h5 class="text-primary">
                    Selecciona el Tipo de Falla
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo de Falla"
                      rules="required"
                    >
                      <v-select
                        v-model="fallaBt.tipoFalla"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        :options="tiposFalla"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="fallaBt.tipoFalla && fallaBt.tipoFalla.id === 2"
                  cols="12"
                  sm="12"
                >
                  <h5 class="text-primary">
                    Número de Transformador
                  </h5>
                  <b-form-group>
                    <b-form-input
                      v-model="fallaBt.noTransformador"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <h5 class="text-primary">
                    Dirección
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Dirección"
                      rules="required"
                    >
                      <b-form-textarea
                        id="textarea-no-resize"
                        v-model="direccion"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                        no-resize
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <h5 class="text-primary">
                    Fotografía Panorámica
                  </h5>
                  <b-form-group>
                    <imagen
                      :key="'panoramica'"
                      :leyenda="'panoramica'"
                      @cargar-file="imagenPanoramica"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <h5 class="text-primary">
                    Solución Provisional
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Solución Provisional"
                      rules="required"
                    >
                      <b-form-textarea
                        id="textarea-no-resize"
                        v-model="fallaBt.solucionProvisional"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                        no-resize
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <h5 class="text-primary">
                    Fotografía de Solución
                  </h5>
                  <b-form-group>
                    <imagen
                      :key="'fotografiaSolucion'"
                      :leyenda="'fotografiaSolucion'"
                      @cargar-file="imagenSolucion"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <h5 class="text-primary">
                    Dictamen
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Dictamen"
                      rules="required"
                    >
                      <b-form-textarea
                        id="textarea-no-resize"
                        v-model="fallaBt.dictamen"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                        no-resize
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <h5 class="text-primary">
                    Recomendación
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Recomendacion"
                      rules="required"
                    >
                      <b-form-textarea
                        id="textarea-no-resize"
                        v-model="fallaBt.recomendacion"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                        no-resize
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <h5 class="text-primary">
                    Observaciones
                  </h5>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Recomendación"
                      rules="required"
                    >
                      <b-form-textarea
                        id="textarea-no-resize"
                        v-model="fallaBt.observaciones"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                        no-resize
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="!isNew || (isNew && !isReadOnly)">
                <!-- BOTONES -->
                <b-col
                  class="d-flex justify-content-end"
                  cols="12"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Guardar
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, VBModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { updateCreateFalla } from '@/utils/mantenimiento/fallasBt/fallasBtUtils'
import Ripple from 'vue-ripple-directive'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { saveImagen } from '@/utils/imagenes'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getlistado } from '@/utils/catalogos'

export default {
  name: 'FallaLineaBtForm',
  components: {
    Imagen,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BCard,
    BForm,
    BRow,
    BCol,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    item: {
      type: String,
      required: false,
      default: '',
    },
    isNew: {
      type: Boolean,
      default: false,
      required: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      fallaBt: {
        fechaCreacion: new Date(),
        tipoFalla: null,
        posteReferencia: null,
        solucionProvisional: null,
        dictamen: null,
        recomendacion: null,
        observaciones: null,
        noTransformador: '',
        tracking: [
          {
            estado: 'INGRESADA',
            horaFecha: new Date(),
          },
        ],
        estado: 'INGRESADA',
        usuario: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      seguimientoEstado: null,
      fotoPanoramica: '',
      fotoSolucion: '',
      fotoSeguimiento: '',
      required,
      tiposFalla: [],
      seguimiento: [],
      localization: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
      direccion: '',
    }
  },
  async created() {
    this.tiposFalla = await getlistado(15, 'Tipos de Falla BT', false, this.usuario)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    changeEditable() {
      this.editable = false
    },
    async cancel() {
      this.editable = true
    },
    async guardarFalla() {
      if (!this.fotoPanoramica || this.fotoPanoramica === '') {
        mensajeError('Debe Ingresar una Fotografía Panorámica de la Falla!')
        return
      }
      if (!this.fotoSolucion || this.fotoSolucion === '') {
        mensajeError('Debe Ingresar una Fotografía de la Solución!')
        return
      }
      if (this.fotoSeguimiento && this.seguimientoEstado === '') {
        mensajeError('Debe Ingresar una Fotografía del Seguimiento!')
        return
      }
      this.show = true

      if (this.localization === null || this.localization === undefined) {
        mensajeError('Error obteniendo geolocalización, intente nuevamente!')
        return
      }

      this.fallaBt.usuario = {
        id: this.usuario.id,
        nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        email: this.usuario.email,
        telefono: '',
      }

      this.fallaBt.direccion = this.direccion

      if (this.seguimientoEstado && this.seguimientoEstado.id === 1) {
        this.fallaBt.tracking.push({
          estado: 'NOTIFICADA',
          horaFecha: new Date(),
        })
        this.fallaBt.estado = 'NOTIFICADA'
      }
      const item = await updateCreateFalla(this.fallaBt, 2)

      if (!item) {
        mensajeError('Inconvenientes almacenando la falla!, intente nuevamente!')
        return
      }

      const urlBucket = `fallas-bt/${item.id}`
      const isMobile = window.screen.width <= 760

      const imagen1 = await saveImagen(urlBucket, this.localization, this.fotoPanoramica, 'Foto Panorámica', '', this.fallaBt.usuario, isMobile)
      if (imagen1) item.seguimiento.push(imagen1)
      const imagen2 = await saveImagen(urlBucket, this.localization, this.fotoSolucion, 'Foto Solución Provisional', '', this.fallaBt.usuario, isMobile)
      if (imagen2) item.seguimiento.push(imagen2)
      if (this.seguimientoEstado) {
        const imagen3 = await saveImagen(urlBucket, this.localization, this.fotoSeguimiento, 'Foto Seguimiento', '', this.fallaBt.usuario, isMobile)
        if (imagen3) item.seguimiento.push(imagen3)
      }
      await updateCreateFalla(item, 1)

      mensajeInformativo('Guardada', 'Falla Linea BT almacenada perfectamente!')
      this.$router.replace('/falla-linea-bt').then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Falla BT Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Falla BT ingresada correctamente!',
          },
        })
      })
      this.show = false
    },
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.guardarFalla()
          }
        })
    },
    imagenPanoramica(file) {
      this.fotoPanoramica = file
    },
    imagenSolucion(file) {
      this.fotoSolucion = file
    },
    imagenSeguimiento(file) {
      this.fotoSeguimiento = file
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
